// WaGen___HASHKEY__37de949a_2019-12-27 13:50:42 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
获取业务流程信息树
*/
export function ywflTreeService_getYwflTree(jgbm,ywfl,ywlb,ywzl, meta) {
	return fetch({
		url : 'psdmsgdgl/service/YwflTreeService/getYwflTree',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				ywfl : ywfl, //String
				ywlb : ywlb, //String
				ywzl : ywzl //String
			}
		}
	})
}

