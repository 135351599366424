// WaGen___HASHKEY__821c5d1b_2020-03-13 11:38:41 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
根据机构编码获取核算单元树
*/
export function hsdyManageService_getHsdyByJgbm(jgbm, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/HsdyManageService/getHsdyByJgbm',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm //String
			},
			pagination : pagination
		}
	})
}

/* ---
根据机构编码、单元编码获取核算单元树
*/
export function hsdyManageService_getHsdyByJgbmAndDybm(jgbm, dybm, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/HsdyManageService/getHsdyByJgbmAndDybm',
		method : 'post',
		data : {
			param : {
				jgbm : jgbm, //String
				dybm: dybm //String
			},
			pagination : pagination
		}
	})
}

