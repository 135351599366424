// WaGen___HASHKEY__4cccf6cf_2020-03-13 11:38:41 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
未提供注释
*/
export function orgManageService_getOrgTree(orgID, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/OrgManageService/getOrgTree',
		method : 'post',
		data : {
			param : {
				orgID : orgID //String
			},
			pagination : pagination
		}
	})
}

/* ---
未提供注释
*/
export function orgManageService_getSysJgxxTreeByWbid(pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/OrgManageService/getSysJgxxTreeByWbid',
		method : 'post',
		data : {
			pagination : pagination
		}
	})
}

/* ---
根据上级组织编码获取机构组织树
*/
export function orgManageService_getJgzzBySjzzbm(sjzzbm,showZz, pagination, meta) {
	return fetch({
		url : 'psdmsqxgl/services/OrgManageService/getJgzzBySjzzbm',
		method : 'post',
		data : {
			param : {
				sjzzbm : sjzzbm, //String
				showZz : showZz //boolean
			},
			pagination : pagination
		}
	})
}

