<template>
  <van-uploader
    v-model="fileList" 
    show-upload 
    multiple
    :max-count="maxCount"
    :upload-text="uploadText"
    :before-read="handleBeforeRead"
    :before-delete="handleBeforeDelete"
    @delete="handleDelete"
  />
</template>

<script>
import { parseTime } from '@/utils';
import { mapGetters } from 'vuex';
import { fileManage_upload, fileManage_getList, fileManage_delete} from '@/utils/fileManage';

import { Uploader } from 'vant';
import Compressor from 'compressorjs';
export default {
  name: 'GzdFjUploader',
  components: {
    [Uploader.name]: Uploader
  },
  props: {
    gzdxx: {
      type: Object,
      default: () => {
        return {};
      }
    },
    yhbh: {
      type: [String, Number],
      default: ''
    },
    gnid: {
      type: String,
      default: ''
    },
    uploadText: {
      type: String,
      default: ''
    },
    maxCount: {
      type: String,
      default: '-'
    },
    bzsm: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      fileList: []
    }
  },
  computed: {
    ...mapGetters(['token','jgbm','czyh']),
  },

  methods: {
    // 外部主动调用
    getFileList() {
      const {jgbm, qdrq, ywbh} = this.gzdxx;

      fileManage_getList({jgbm, qdrq, ywbh, bh: this.yhbh, gnid: this.gnid}).then(data => {
        console.log("LIST:", data);
        this.fileList = data;
      });
    },
    // 外部主动调用
    uploadFileList() {
      for(let i = 0; i < this.fileList.length; i++) {
        const file = this.fileList[i];
        if (file.status != 'done') {
          this.uploadFile(file, i);
        }
      }
    },

    uploadFile(file, index) {
      const datetimeNow = parseTime(new Date(), '{y}-{m}-{d} {h}:{m}:{s}');
      let vm = this;
      const {jgbm, qdrq, ywbh} = this.gzdxx;
      file.status = "uploading";
      vm.fileList.splice(index, 1, file);
      fileManage_upload(file, {
        params: {
          jgbm: jgbm,
          qdrq: qdrq, 
          ywbh: ywbh,
          fjfl: '1', // 用户资料
          bh: this.yhbh,
          bzsm: this.bzsm,
          gnid: this.gnid
        },
        loginToken: this.token,
        onProgress: function (ev, percentage, file) {
          console.log("onProgress:", ev, percentage, file)
          vm.fileList.splice(index, 1, file);
        },
        onSuccess: function(file) {
          console.log("onSuccess:", file);
          vm.fileList.splice(index, 1, file);
        },
        onFail: function(err, file) {
          console.log("FAIL:", err, file)
          vm.$toast('上传失败：'+ err.message);
          vm.fileList.splice(index, 1, file);
        }
      });
    },

    handleBeforeRead(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: 0.6,
          success: function (result){
            result = new File([result],file.name,{type:file.type,lastModified:Date.now()})
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    async handleBeforeDelete(file, {name, index}) {
      console.log(arguments);
      const result = await this.$dialog({
        type: "warning",
        title: "提示",
        message: "是否删除？",
        showCancelButton: true,
        confirmButtonText: "删除"
      });
      
      return result && result == "confirm";
    },
    handleDelete(file, {name, index}) {
      let vm = this;
      // 删除接口调用
      if (file.fjid || file.status == 'done') {
        fileManage_delete(file).then(res => {
          this.$toast('已删除附件');
          vm.fileList.splice(index, 1);
        }).catch(err => {
          this.$toast('附件删除失败:'+ err.message);
        });
      }
    }
  }
}
</script>